.pagination {
    display: flex;
    margin-top: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
}

.pagination li {
    flex: 1;
    list-style: none;
    text-align: center;
    padding: 0.25rem 0.5rem;
    border-right: 1px solid #ccc;

    &:last-of-type {
        border: none;
    }

    & a {
        display: block;
    }
}

.selected {
    background-color: #0BC5EA;
    color: white;
    font-weight: bold;
}
